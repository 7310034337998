<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="customers"
      :items-per-page="10"
      :search="search"
      sort-by="first_name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiAccountTie }}</v-icon>
            {{ $route.meta.title }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-layout>
            <v-spacer></v-spacer>
            <div v-for="(permission, index) in permission" :key="index">
              <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                  <v-btn
                    v-show="user_submenu.create == 1 && permission.role_id == 3"
                    color="accent"
                    rounded
                    @click.prevent="$router.push({ name: 'addnewcustomer' })"
                  >
                    <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                    <span>Create New </span>
                  </v-btn>
                </div>
              </div>
            </div>
            <div>
              <v-btn
                v-show="currentUser.role_id == 1"
                color="accent"
                rounded
                @click.prevent="$router.push({ name: 'addnewcustomer' })"
              >
                <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                <span>Create New </span>
              </v-btn>
            </div>
          </v-layout>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(customer, index) in items" :key="index">
            <td>
              <v-icon class="mr-1">{{ icons.mdiAccountTie }}</v-icon
              >{{ customer.first_name }}
            </td>
            <td>{{ customer.last_name }}</td>
            <td>{{ customer.company_name }}</td>
            <td>{{ customer.email }}</td>
            <td>{{ formatDate(customer.created_at) }}</td>
            <td>
              <v-layout class="mx-1">
                <div v-for="(permission, index) in permission" :key="index">
                  <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                    <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            v-show="user_submenu.update == 1 && permission.role_id == 3"
                            color="primary"
                            @click.prevent="
                              $router.push({
                                name: 'editcustomer',
                                params: { id: customer.customer_id },
                              })
                            "
                            fab
                            x-small
                            dark
                          >
                            <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <div v-show="currentUser.role_id == 1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        @click.prevent="
                          $router.push({
                            name: 'editcustomer',
                            params: { id: customer.customer_id },
                          })
                        "
                        fab
                        x-small
                        dark
                      >
                        <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </div>
                <div>
                  <div v-for="(permission, index) in permission" :key="index">
                    <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                      <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              v-show="user_submenu.delete == 1 && permission.role_id == 3"
                              @click.prevent="deleteCustomer(customer.customer_id)"
                              color="error"
                              fab
                              x-small
                              dark
                              class="mx-1"
                            >
                              <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                  <div>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          v-show="currentUser.role_id == 1"
                          @click.prevent="deleteCustomer(customer.customer_id)"
                          color="error"
                          fab
                          x-small
                          dark
                          class="mx-1"
                        >
                          <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>

      <template v-slot:no-data>
        <v-btn color="accent" @click="getCustomers"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiAccountTie, mdiMagnify, mdiDotsVertical, mdiPlus, mdiPencilOutline, mdiDeleteForeverOutline } from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {},

  created: function() {
    this.getCustomers()
    this.getAuthorizations()
  },
  data() {
    return {
      headers: [
        {
          text: 'First Name',
          value: 'first_name',
        },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Company Name', value: 'company_name' },
        { text: 'Email', value: 'email' },
        { text: 'Created At', value: 'created_at', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      customers: [],
      permission: {},
      search: '',
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
      },
    }
  },

  methods: {
    getCustomers: function() {
      this.$store
        .dispatch('customer/fetchCustomers')
        .then(response => {
          this.customers = this.fetchCustomers
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthorizations: function() {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 7,
          submenu_id: 3,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteCustomer: function(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })

            this.$store
              .dispatch(`customer/deleteCustomer`, { customer_id: id })
              .then(response => {
                this.getCustomers()
              })

              .catch(err => {
                console.log(err)
              })
          }
        })
    },

    formatDate(value) {
      return moment(value).format('l')
    },
  },

  computed: {
    ...mapGetters({
      fetchCustomers: 'customer/fetchCustomers',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>
